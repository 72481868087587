<template>
  <div class="container-fluid">
    <div v-if="!nodata">
      <div class="row">
        <div class="col-12">
          <b>First name </b>: {{ userInfo.first_name_th }}
        </div>
        <div class="col-12"><b>Last name </b>: {{ userInfo.last_name_th }}</div>
        <div class="col-12"><b>Point </b>: {{ userInfo.total_point }}</div>
        <div class="col-12">
          <b>Tier </b>: {{ userInfo.display }} ({{
            userInfo.member_level_name
          }})
        </div>
      </div>
      <section>
        <div
          class="card mt-3 bg-gray"
          v-for="campaign of campaignList"
          :key="campaign.id"
        >
          <div class="card-body px-1 py-2">
            <div class="d-flex justify-content-between">
              <div>
                <b>{{ campaign.name }}</b>
              </div>
              <div class="edit-items">
                <a :href="campaign.url" target="_blank">ดูรายละเอียด</a>
              </div>
            </div>
            <div class="f-12">{{ momentFormat(campaign.created_time) }}</div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <div class="card bg-gray">
        <div class="card-body"><div class="text-center">ไม่มีข้อมูล</div></div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      campaignList: [],
      userInfo: {},
      nodata: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    momentFormat(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    async getData() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.tel == "") return (this.nodata = true);
      const res = await this.axios(
        `https://api-hourglass-admin-dev.dosetech.co/api/user/${params.tel}`
      );
      if (res.data.result == 1) {
        this.campaignList = res.data.detail.campaignInfo;

        this.userInfo = res.data.detail.userInfo;
      } else {
        this.nodata = true;
      }
    },
  },
};
</script>

<style lang="css">
.panel {
  text-align: left;
  white-space: nowrap;
  display: flex;
  font-size: 14px;
  font-weight: bold;
}
.bg-gray {
  background-color: whitesmoke;
}
.f-12 {
  font-size: 12px;
}
.edit-items a {
  text-decoration: underline;
  color: black;
  font-size: 12px;
}
</style>
