<template>
  <div class="wrapper">
    <ChatDetail />
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import ChatDetail from "./components/ChatDetail.vue";

export default {
  name: "App",
  components: {
    ChatDetail,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap");
/* @font-face {
  font-family: "Kanit-Regular";
  
} */
#app {
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
  margin-top: 15px;
  font-size: 14px;
}
</style>
